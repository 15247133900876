.card {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: auto;
	gap: 45px;
	/* align-content: space-around;
    justify-content: space-evenly; */
}

.technologies {
	font-size: 25px;
	margin-top: 0px;
	margin-bottom: 0px;
	display: flex;
	justify-content: space-evenly;
	/* align-content: space-around; */
}

#flippy {
	padding: 0px;
	border-radius: 25%;
}

@media only screen and (min-width: 945px) and (max-width: 1250px) {
	.card {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-rows: auto;
	}
}

@media only screen and (min-width: 635px) and (max-width: 944px) {
	.card {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: auto;
	}
}

@media only screen and (max-width: 634px) {
	.card {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: auto;
	}
}
