/* @media only screen and (min-width : 1152px) {
    .Skills {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }
} */

@media only screen and (max-width: 600px) {
	.Skills {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
}

@media only screen and (min-width: 1150px) {
	.Skills {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		justify-content: space-evenly;
	}
}

/* @media only screen and (min-width: 601px) and (max-width: 1149) {
	.Skills {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		justify-content: space-evenly;
	}
} */
