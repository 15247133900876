.About {
    padding-top: 0px;
    padding-left: 50px;
    padding-right: 50px;
    display: flex;
    flex-direction: column;
}

.account {
    margin-top: 0px;
    padding-top: 0px;
}

.statement {
    display: flex;
    justify-content: flex-start; 
    align-items: flex-start; 
    font-size: calc(10px + 2vmin);
    margin-bottom: 10px;
    margin-top: 10px;
}
.brand {
    display: flex;
    flex-direction: column;
    text-align: left;
    /* justify-content: flex-start;  */
}

@media only screen and (max-width: 724px) {
    .account {
        padding-top: 0px;
    }
    .About {
        padding-top: 0px;
    }
    #about {
        padding-bottom: 0px;
        margin-bottom: 0px;
    }
}

@media only screen and (min-width: 1110px) {
    .About {
        padding-top: 20px;
        padding-left: 90px;
        padding-right: 90px;
        display: grid;
        grid-template-columns: 25% 75%;
    } 
    .account {
        display: flex;
        justify-items: center;
        align-items: center;
    }
    .statement {
        margin-bottom: 15px;
        margin-top: 15px;
    }
}