.App-dark {
	text-align: center;
	min-height: 100vh;
	background-color: black;
	color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
}

.App-light {
	text-align: center;
	min-height: 100vh;
	background-color: #f8f8f8;
	color: black;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
}

h1 {
	padding-top: 90px;
}
