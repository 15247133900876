.landing-page-dark {
	background-image: url("https://i.ibb.co/6RBSnLk/Background.jpg");
	height: 100vh;
	width: 100vw;
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	flex-direction: column;
	justify-content: center; /* y-axis*/
	align-items: center; /* x-axis for icons*/
}

.landing-page-light {
	background-image: url("https://i.ibb.co/CbxcbCy/Background2.jpg");
	height: 100vh;
	width: 100vw;
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

#name {
	padding-left: 10px;
}

.title-dark {
	color: #c0c0c0;
}

.title-light {
	color: #282828;
}

/* .with-purpose {
	color: #f2f3f4;
} */

.IntroduceYourself {
	padding-top: 50px;
}

@media only screen and (max-width: 650px) {
	.IntroduceYourself {
		margin: 0px 35px;
	}
}
